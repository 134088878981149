<template>
    <div>
        <div>
            <div style="padding-top:10px;padding-bottom: 0px;" class="clearfix table-tools">
                <div class="buttons">
                    <a-form layout='inline'>
                        <a-form-item>
                            <a-button v-if="showNotify" @click='showEditModal()' type="primary" icon="sound" >推送提醒</a-button>
                        </a-form-item>
                    </a-form>
                </div>
                <div class="search">
                    <a-form layout='inline' @submit="searchMsg">
                        <a-form-item>
                            <a-select style="width: 200px" v-model='searchParams.search.userid' allowClear showSearch @change="handleUserChange" placeholder="请选择员工" :filterOption="filterOption">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['userid']">{{ d['name'] }}</a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-range-picker
                            :ranges="rangesData"
                            v-model='searchDatas.add'
                            :disabled-date="disabledDate"
                            allowClear
                            style="width: 240px"
                            @change="(val,time)=>handleTime(val,time,'add')" />
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="searchMsg" :loading="loading" html-type="submit" type="primary" >搜索</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
        </div>
        <div
            class="keywords-list"
            :style="{ height:wHeight}"
            v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
            ref="container"
        >
            <vue-element-loading :active="loading && msgList.length==0" color="#00cca2" spinner="spinner"/>
            <a-affix :target="() => this.$refs.container">
                <div class="keywords-list-bg">
                    <div class="keywords-list-word">
                        <div style="width:100px">全部关键词</div>
                        <div>
                            <a-input
                                v-if="inputVisible"
                                ref="input"
                                type="text"
                                size="small"
                                :style="{ width: '91px', marginRight:'10px'}"
                                :value="inputValue"
                                @change="handleInputChange"
                                @blur="handleInputConfirm"
                                @keyup.enter="handleInputConfirm"
                                />
                                <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                                <a-icon type="plus" />添加关键词
                                </a-tag>
                        </div>
                        <div style="flex:1">
                            <a-tag style="cursor: pointer;border-radius:10px;" :color="!checkTag?'#00cca2':''" @click="handleCheckTag('')">全部</a-tag>
                            <a-tag style="cursor: pointer;border-radius:10px;" v-for="tag in tagsList" :key="tag" :color="checkTag == tag?'#00cca2':''" @click="handleCheckTag(tag)" :closable="isClosable" @close="handleClose(tag)">{{tag}}</a-tag>
                        </div>
                        <div style="width:16px;cursor: pointer;">
                            <a-icon v-if="showNotify" @click="isClosable = true" style="font-size:16px" type="setting" />
                        </div>
                    </div>
                    <div v-if="isClosable" class="keywords-list-delete" style="text-align:right;margin-top:10px">
                        <a-space>
                            <a-button @click="isClosable = false;tagsList = tags" size='small'>取消</a-button>
                            <a-button @click="addKeywords([])" size='small' type="primary">确认</a-button>
                        </a-space>
                    </div>
                </div>
                <div style="margin-left:15px;background:#fff">
                    <a-icon type="appstore" />
                    <span style="margin-left:5px">共有{{chatCount.toLocaleString()}}条关键字提醒</span>
                </div>
            </a-affix>
            <div v-for="item in msgList" :key="item.id" class="keywords-list-bg keywords-list-item">
                <div class="keywords-list-item-nav">
                    <a-avatar shape="square" :src="item.avatar" :size="16" icon="user" />
                    <span style="margin-left:5px">{{item.name}} 与</span>
                    <a-avatar v-if="item.toavatar" style="margin:0 5px" shape="square" :src="item.toavatar" :size="16" icon="user" />
                    <span v-if="item.session_type != 'staff_group' && item.session_type != 'external_group'">{{item.toname}}的聊天</span>
                    <span v-else>【{{item.toname}}】的聊天</span>
                    <div class="keywords-list-item-time">{{item.msgtime_txt}}</div>
                </div>
                <div class="keywords-list-item-content">
                    <a-avatar shape="square" :src="item.avatar" :size="32" icon="user" />
                    <div class="keywords-list-item-info">
                        <div>{{item.name}}</div>
                        <!-- <div>{{item.text.content}}</div> -->
                        <div @click="showChatModal(item)" style="font-size:12px;color:#909090;cursor: pointer;" v-html="convertText2Emoji(item)"></div>
                    </div>
                    <div @click="showChatModal(item)" class="keywords-list-item-look">查看</div>
                </div>
            </div>
            <div class="keywords-more" v-if="busy">没有更多了~</div>
        </div>
        <div>
            <vue-element-loading :active="loading && msgList.length>0 && !busy" color="#00cca2" spinner="spinner"/>
        </div>
        <editModal v-if="isEditModal" :item="staffList" />
        <chatModal v-if="isChatModal" :item="modalData" />
    </div>
</template>

<script>
    import moment from 'moment'
    import { delay } from '@/utils/timeFormat'
    import editModal from './editModal'
    import chatModal from './chatModal'
    import tableMixins from '@/common/mixins/table'
    import ranges from "@/common/mixins/ranges"
    import infiniteScroll from 'vue-infinite-scroll'
    export default {
        name:'keywords',
        data() {
            return {
                searchDatas:{},
                myHeight:0,
                chatCount:0,
                busy:false,
                loading:false,
                checked:false,
                showNotify:false,
                isEditModal:false,
                inputVisible:false,
                isClosable:false,
                isChatModal:false,
                modalData:{},
                inputValue:'',
                tagsList:[],
                tags:[],
                checkTag:'',
                msgList:[],
                staffList:[],
                studios:[],
                searchParams: {
                    "page": 0,
                    "per-page": 30,
                    "search": {},
                    "sort": ''
                },
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        computed:{
            wHeight(){
                return `${Number(this.myHeight) - 250}px`
            }
        },
        components: {
            editModal,
            chatModal,
        },
        directives: { infiniteScroll },
        mixins: [ tableMixins , ranges],
        created () {
            let group_id = this.$ls.get('employee_group_id')
            if(group_id.indexOf(1) != -1){
                this.showNotify = true
            }
            this.myHeight = this.windowHeight()
            this.getStaff()
        },
        methods: {
            hideVideoModal(){
                
            },
            filterOption(input, option) {
                return option.componentOptions.children[0].text.indexOf(input) >= 0;
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getList(){
                let res = await this.$store.dispatch('settingWxworkInvalidKeywordsAction', {})
                this.tagsList = res.data.invalid_keywords
                this.staffList = res.data.invalid_keywords_notify_ids
                this.tags = res.data.invalid_keywords
            },
            async getMsg(){
                this.loading = true
                if(this.checkTag){
                    this.searchParams.keyword = [this.checkTag]
                }else{
                    this.searchParams.keyword = undefined
                }
                this.searchParams['per-page'] = 30
                await this.$store.dispatch('wxworkMsgInvalidAction', {data:this.searchParams})
                .then(res=>{
                    if(this.msgList.length == 0){
                        this.msgList = res.items
                    }else{
                        this.msgList = [...this.msgList,...res.items]
                    }
                    if(this.msgList.length >= res._meta.totalCount){
                        this.busy = true
                    }
                    this.chatCount = res._meta.totalCount
                    this.loading = false
                })
                .catch(err=>{
                    this.loading = false
                })
            },
            async addKeywords(arr){
                await this.$store.dispatch('settingWxworkSetKeywordsAction', {data:{invalid_keywords:[...this.tagsList,...arr]}})
                .then(res=>{
                    this.$message.success('操作成功~')
                    this.inputVisible = false
                    this.isClosable = false
                    this.inputValue = ''
                    this.getList()
                })
                .catch(err=>{
                    this.$message.error('操作失败~')
                })
            },
            async getStaff(val){
                let obj = {name:val}
                let res = await this.$store.dispatch('wxworkStaffIndexAction', {data:obj})
                this.studios = res.items
            },
            handleSearch(val){
                this.getStaff(val)
            },
            handleTime(val,time,name){
                this.searchDatas[name] = val
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
                this.searchMsg()
            },
            handleUserChange(){
                this.searchMsg()
            },
            handleCheck(checked){
                console.log(checked)
            },
            showEditModal(){
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            showChatModal(item){
                if(item.session_type == 'staff_group' || item.session_type == 'external_group'){
                    item.session_ext_id = item.roomid
                }
                this.modalData = item
                console.log(this.modalData);
                this.isChatModal = true 
            },
            hideChatModal(){
               this.isChatModal = false 
            },
            handleInputChange(e) {
                this.inputValue = e.target.value
            },
            showInput() {
                this.inputVisible = true
            },
            handleInputConfirm(){
                if(this.inputValue){
                    this.addKeywords([this.inputValue])
                }
            },
            handleClose(removedTag) {
                const tagsList = this.tagsList.filter(tag => tag !== removedTag)
                this.tagsList = tagsList
            },
            handleCheckTag(tag){
                this.checkTag = tag
                this.msgList = []
                this.searchParams.page = 1
                this.busy = false
                this.getMsg()
            },
            handleInfiniteOnLoad(){
                if(!this.busy){
                    this.searchParams.page++
                    this.getMsg()
                }
            },
            convertText2Emoji(item){
                return this.changeColor(item.text.content,item.invalid_keyword)
            },
            changeColor(content, keywords) {
                let str = content
                keywords.map((keyitem, keyindex) => {
                    // 匹配关键字正则
                        let replaceReg = new RegExp(keyitem, "g")
                        // 高亮替换v-html值
                        let replaceString =
                        '<span class="highlight"' +
                        ' style="color:#00cca2;">' +
                        keyitem +
                        "</span>"
                        str = str.replace(
                        replaceReg,
                        replaceString
                        )
                });
                return str
            },
            searchMsg(e){
                e?e.preventDefault():''
                this.loading = true
                delay(() => {
                    this.searchParams.page = 1
                    this.msgList = []
                    this.busy = false
                    this.getMsg()
                }, 1000)
            },
            windowHeight() { 
                var de = document.documentElement
                return self.innerHeight||(de && de.offsetHeight)||document.body.offsetHeight;
            },
        },
    }
</script>

<style lang="scss" scoped>
    .keywords-list{
        overflow-y: scroll;
        &-bg{
            background: #fff;
            padding: 10px 15px;
            border-radius: 2px;
        }
        &-word{
            display: flex;
        }
        &-item{
            &-nav{
                display: flex;
                align-items: center;
                position: relative;
                font-size: 12px;
                color: #909090;
                border-bottom: 1px dashed #eee;
                padding: 5px 0;
            }
            &-time{
                position: absolute;
                right: 0px;
            }
            &-content{
                display: flex;
                margin-top:10px
            }
            &-info{
                flex:1;
                margin-left:10px
            }
            &-look{
                width: 40px;
                cursor: pointer;
                color: #00cca2;
            }
        }
    }
    .keywords-more{
        height: 30px;
        text-align: center;
        line-height: 30px;
    }
</style>